<template>
  <div class="box">
    <div class="topbox">
      <div class="tleftbox" @click="disto(questionItem.infoId)">
        <div class="questiontitle">
          <div class="q">
            <img src="~assets/Vector.svg" alt="">
             <span> 问题：</span>
          </div>
          <div class="question">
            <span v-html="questionItem.infoTitle"></span>
          </div>
        </div>
        <div class="group">
          <span>专业解答</span>
          <a>「查看详细解答」</a>
        </div>
      </div>
      <div class="toanswer">
        <!-- <span><a @click="docto()">向他提问</a></span> -->
         <el-button type="primary" plain @click="docto()">向他提问</el-button>
      </div>
    </div>
    <div class="bottombox" @click="disto(questionItem.infoId)">
      <div class="user">
        <div>
          <el-avatar :size="50" :src="questionItem.avatar"></el-avatar>
          <!-- <img src="~assets/Oval.png" alt=""> -->
        </div>
        <div class="ubox">
          <div class="namebox">
            <div><span class="name">{{questionItem.doctorName}}</span></div>
            <div class="position"><span>{{questionItem.doctorTitle}}</span></div>
          </div>
          <div class="hos"><span>{{questionItem.hospital}}</span></div>
        </div>
      </div>
      <div class="time">
        <div class="day"><span>{{time}}</span></div>
        <span>{{questionItem.browse}}次查看</span>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from '@/util/formateDate'
import HighLight from 'util/highLight'
export default {
  name: 'QusetionAnswer',
  data () {
    return {
      time: ''
    }
  },
  props: {
    questionItem: {
      type: Object,
      default () {
        return {
        }
      }
    },
    type: {
      type: String
    },
    search_text: {
      type: String
    }
  },
  computed: {
    title () {
      return HighLight('title', this.questionItem.title, this.search_text)
    }
  },
  mounted () {
    // console.log(this.type)
    const d = new Date(this.questionItem.startTime)
    this.time = formatDate(d)
    // console.log(this.time)
  },
  methods: {
    disto (e) {
      // this.$router.push('/questiondetail/' + e)
      // // sessionStorage.setItem('nav', '5')
      this.$router.push({ path: '/mobQuestionCenterDel', query: { qaId: e } })
    },
    docto () {
      // // sessionStorage.setItem('nav', '6')
      this.$router.push('/moreservice/downapp')
    }
  }
}
</script>

<style scoped>
.box{
  /* position: absolute; */
  /* width: 1038px; */
  height: 179px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
::v-deep .el-avatar>img{
  height: unset;
  width: 100%;
}
a{
  cursor: pointer;
  text-decoration: none;
}
.topbox {
  display: flex;
  height: 105px;
  border-bottom: 1px solid #F5F5F5;
}
.tleftbox{
  flex: 1;
  margin-left: 31px;
}

.toanswer{
  /* position: absolute; */
  height: 60px;
  float: right;
  margin-right: 60px;
  margin-top: 40px;
}
/* .toanswer span{
  height: 78px;
  font-size: 20px;
  line-height: 28px;
  padding: 16px 20px;
  border: 2px solid #4263EB;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #4263EB;
} */
/* .toanswer span:hover{
  background-color: #4263EB;
  color: #FFFFFF;
} */
.group{
  font-size: 20px;
  line-height: 28px;
}
a{
  text-decoration:none;
}
.group a{
  /* position: absolute; */
  width: 160px;
  height: 28px;
  left: 130px;
  color: #F6A609;
}
.group span{
  /* position: absolute; */
  width: 100px;
  height: 28px;
  left: 30px;
  color: #595959;
}
.questiontitle{
  display: flex;
  padding: 0px;
  /* position: absolute; */
  /* width: 90px; */
  height: 28px;
  margin-top: 22px;
  margin-bottom: 17px;
}
.q span{
  position: static;
  /* width: 60px; */
  height: 28px;
  margin-left: 4px;
  font-size: 20px;
  line-height: 28px;
  color: #595959;
}
.q{
  height: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}
.question{
  flex: 1;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
  color: #262626;
}
.bottombox{
  display: flex;
}
.user{
  /* position: absolute; */
  margin-left: 2.5%;
  /* margin-top: 64.8%; */
  /* margin-bottom: 8.38%; */
  margin-top: 1%;
  flex: 1;
  display: flex;
}
.ubox{
  margin-left: 25px;
}
.name{
  /* position: absolute; */
  width: 48px;
  height: 24px;
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.user img{
  border: 1px solid #FAFAFA;
  box-sizing: border-box;
}
.namebox{
  display: flex;
  margin-bottom: 2px;
}
.position span{
  font-size: 11px;
  margin-left: 12px;
  line-height: 16px;
  border: 1px solid #4263EB;
  padding: 1px 2px;
  border-radius: 2px;
  color: #4263EB;
}
.hos{
  color: #595959;
}
.time{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 240px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  margin-top: 24px;
  color: #BFBFBF;
}
.time span{
  position: static;
  /* width: 51px; */
  height: 20px;
  left: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.day span{
  position: static;
  width: 68px;
  height: 20px;
  margin-right: 20px;
}
</style>
